<template>
    <div>
        <div class="ma-2 pa-2" style="display: flex; flex-direction:row; justify-content:space-between;padding:8px; margin:8px">
            <div style="font-size: 18px; font-weight: 600; font-style: normal">Health Articles</div>
            <img  class="closeButton" src="https://d3vsnl8idgwrlw.cloudfront.net/closeCircular.svg" @click="closeIframe"/>
        </div>
        <iframe class="ma-2" :src="iframeSrc" width="400" height="800" frameborder="0"></iframe>
    </div>
</template>

<script>
export default {
    name: 'HealthArticles',
    data() {
        return {
            iframeSrc: 'https://healthdigest.mymedicine.com.mm/'
        };
    },
    methods: {
        closeIframe() {
            this.$router.go(-1);
        }
    }
};
</script>
  